import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import EmailScreen from "./EmailScreen";
import CodingScreen from "./CodingScreen";
import TraditionalScreen from "./TraditionalScreen";
import SocialScreen from "./SocialScreen";

const Wrap = styled.div`
    background: #364156;
    height: 70vh;
    width: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
        width: 80%;
    }
`;

const InnerBox = styled.div`
    height: 85%;
    width: 100%;
    background: teal;
    display: flex;
    overflow: hidden;
`;

const List = styled.div`
    background: #212d40;
    width: 40%;
`;

const ListItemStyle = styled.div`
    margin: 30px;
    font-size: 20px;
    color: grey;
    font-family: "Bebas Neue";
    z-index: 2;
    overflow: visible;
    :hover {
        font-size: 60px;
        color: white;
    }
`;

const ViewBox = styled.div`
    background: #11151c;
    width: 60%;
    display: grid;
    color: white;
`;

const ItemHolder = styled.div`
    grid-row: 1;
    grid-column: 1;
    color: white;
    overflow-y: scroll;
`;

const animation = keyframes`
 0% {width: 0}
 50% {width: 100%}
 100% {width: 0}
`;

const anim = css`
    ${animation} 1s forwards
`;

const Slider = styled.div`
    height: 100%;
    background: #212d40;
    width: 0%;
    z-index: 1;
    grid-row: 1;
    grid-column: 1;
    animation: ${(props) => props.animate && anim};
`;

const ListItem = (props) => {
    return (
        <ListItemStyle
            onMouseEnter={() => {
                props.setAnimate(true);
                setTimeout(() => {
                    props.setItem(props.item);
                }, 500);
            }}
            onMouseLeave={() => {
                props.setAnimate(false);
            }}
        >
            {props.children}
        </ListItemStyle>
    );
};

const ConnectCards = () => {
    const [animate, setAnimate] = useState(false);
    const [item, setItem] = useState(1);

    return (
        <Wrap>
            <InnerBox>
                <List>
                    <ListItem
                        setAnimate={setAnimate}
                        setItem={setItem}
                        item={1}
                    >
                        Social
                    </ListItem>
                    <ListItem
                        setAnimate={setAnimate}
                        setItem={setItem}
                        item={2}
                    >
                        Coding
                    </ListItem>
                    <ListItem
                        setAnimate={setAnimate}
                        setItem={setItem}
                        item={3}
                    >
                        Email
                    </ListItem>
                    <ListItem
                        setAnimate={setAnimate}
                        setItem={setItem}
                        item={4}
                    >
                        Traditional
                    </ListItem>
                </List>
                <ViewBox>
                    <Slider animate={animate} />
                    <ItemHolder>
                        {item === 1 ? (
                            <SocialScreen />
                        ) : item === 2 ? (
                            <CodingScreen />
                        ) : item === 3 ? (
                            <EmailScreen />
                        ) : (
                            <TraditionalScreen />
                        )}
                    </ItemHolder>
                </ViewBox>
            </InnerBox>
        </Wrap>
    );
};

export default ConnectCards;
