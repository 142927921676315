import styled from "styled-components";

export default styled.p`
  font-family: ${(props) => props.theme.fonts.text};
  color: ${(props) => props.theme.colors[props.color] || "white"};
  line-height: ${(props) => props.lineHeight && props.lineHeight}
  margin: ${(props) => (props.margin ? props.margin : "0")}
  padding: ${(props) => (props.padding ? props.padding : "0")}
  font-size: ${(props) => props.fontSize && props.fontSize};
  :hover {
    color: ${(props) => props.hoverColor || "black"};
    cursor: ${(props) => props.linkStyle && "pointer"}
  }
`;

export const TextLink = styled(Text)`
  :hover {
    color: white;
  }
`;
