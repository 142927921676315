import React, { useState, useContext } from "react";
import AppContext from "../context/appContext";
import styled from "styled-components";
import Toast from "./Toast";

const Wrapper = styled.div`
    margin: 30px;
`;

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 20px 0;
`;

const Label = styled.label`
    font-family: ${(props) => props.theme.fonts.heading};
    font-size: 2em;
`;

const Input = styled.input`
    font-family: ${(props) => props.theme.fonts.text};
    padding: 6px;
    color: white;
    border: none;
    background: #212d40;
    :focus {
        outline: #d66853 2px solid;
        background: #364156;
    }
`;

const TextArea = styled.textarea`
    font-family: ${(props) => props.theme.fonts.text};
    padding: 6px;
    color: white;
    border: none;
    resize: vertical;
    overflow: none;
    max-height: 30vh;
    background: #212d40;
    :focus {
        outline: #d66853 2px solid;
        background: #364156;
    }
`;

const Button = styled.button`
    padding: 16px 16px;
    background: #212d40;
    color: white;
    outline: none;
    border: white 2px solid;
    cursor: pointer;
    font-family: ${(props) => props.theme.fonts.text};
    :hover {
        background: #364156;
        color: white;
    }
`;

const EmailScreen = () => {
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [returnEmail, setReturnEmail] = useState("");

    const appContext = useContext(AppContext);
    const { sendMail, mailSuccessMessage, mailFailMessage } = appContext;

    const submitHandler = (e) => {
        e.preventDefault();
        const message = { subject, returnEmail, body };
        sendMail(message);
    };

    return (
        <Wrapper>
            <form onSubmit={submitHandler}>
                <InputGroup>
                    <Label htmlFor="subject">Subject</Label>
                    <Input
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        id="subject"
                    ></Input>
                </InputGroup>
                <InputGroup>
                    <Label htmlFor="body">Body</Label>
                    <TextArea
                        type="text"
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        id="body"
                        rows={7}
                    ></TextArea>
                </InputGroup>
                <InputGroup>
                    <Label htmlFor="returnEmail">Return Email</Label>
                    <Input
                        type="text"
                        value={returnEmail}
                        onChange={(e) => setReturnEmail(e.target.value)}
                        id="returnEmail"
                    ></Input>
                </InputGroup>
                <Button type="submit">Submit</Button>
            </form>
            {mailSuccessMessage ? (
                <Toast text={mailSuccessMessage} />
            ) : mailFailMessage ? (
                <Toast text={mailFailMessage} />
            ) : (
                <></>
            )}
        </Wrapper>
    );
};

export default EmailScreen;
