import React from "react";
import Label from "../components/Label";
import styled from "styled-components";

const Container = styled.div`
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: scroll;
    position: relative;
`;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    @media (min-width: 768px) {
        grid-template-columns: 3fr 2fr;
    }
`;

const LeftSide = styled.div`
    height: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const RightSide = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 100px;
    @media (min-width: 768px) {
        padding: 100px 100px 100px 0;
    }
`;

const Img = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    background: url(${(props) => props.src});
    background-size: cover;
    background-repeat: none;
    background-position: center;
    box-shadow: none;
    @media (min-width: 768px) {
        box-shadow: inset -30px 0px 30px #ffffff;

        background-repeat: none;
    }
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
`;

const P = styled.div`
    font-family: ${(props) => props.theme.fonts.text};
`;

const AboutMe = () => {
    return (
        <Container id="about">
            <Wrapper>
                <LeftSide>
                    <Img src="/images/boy2.png"></Img>
                </LeftSide>
                <RightSide>
                    <Item>
                        <Label>Loves</Label>
                        <P>
                            I love the Lord, I love my wife and I love my son.
                        </P>
                    </Item>
                    <Item>
                        <Label>Interests</Label>
                        <P>
                            I have a passion for the technical and for problem
                            solving. I like to read Kierkegaard, XKCD, and have
                            an interest in woodworking.
                        </P>
                    </Item>
                    <Item>
                        <Label>Math and Coding</Label>
                        <P>
                            I like toying around with numbers and formulas.
                            Finding solutions to problems, automating workflow
                            and finding better ways of achieving a goal excites
                            me. I like content and solutions that are clean,
                            concise and well presented.
                        </P>
                    </Item>
                    <Item>
                        <Label>Skills </Label>
                        <P>
                            NodeJS / Express, GraphQL, TypeScript, Postgres,
                            MongoDB, React, Redux, HTML, CSS, Git, Figma, Google
                            Sheets, Data Analytics
                        </P>
                    </Item>
                    <Item>
                        <Label>Australia ('straya)</Label>
                        <P>
                            I was born, raised and have lived in Australia my
                            whole life sans travel. It's where my family is and
                            it is the place that made me me. Love the lifestyle.
                            Early 2021 my family and I relocated to the USA
                            where I am a now a Permanent Resident.
                        </P>
                    </Item>
                </RightSide>
            </Wrapper>
        </Container>
    );
};

export default AboutMe;
