import React from "react";
import styled from "styled-components";
import Link from "./Link";
import Label from "./Label";
import Text from "./Text";

const Wrapper = styled.div`
  margin: 30px;
`;

const SocialScreen = () => {
  return (
    <Wrapper>
      <Label>Twitter</Label>
      <Link external to="https://twitter.com/pwdrysdale">
        <Text color="three" hoverColor="white">
          <i class="fab fa-twitter"></i> My profile
        </Text>
      </Link>
    </Wrapper>
  );
};

export default SocialScreen;
