import { useReducer } from "react";
import axios from "axios";
import FileDownload from "js-file-download";
import appReducer from "./appReducer";
import AppContext from "./appContext";
import {
    SEND_MAIL_FAIL,
    SEND_MAIL_RESET,
    SEND_MAIL_SUCCESS,
    SEND_CV_FAIL,
    SEND_CV_REQUEST,
    SEND_CV_SUCCESS,
} from "./constants";

const AppState = (props) => {
    const initialState = {};

    const [state, dispatch] = useReducer(appReducer, initialState);

    const sendMail = async ({ subject, returnEmail, body }) => {
        try {
            const headers = {
                "Content-Type": "application/json",
            };
            const { data } = await axios.post(
                "/api/contact",
                { subject, email: returnEmail, body },
                headers
            );

            dispatch({ type: SEND_MAIL_SUCCESS, payload: data });
            setTimeout(() => {
                dispatch({ type: SEND_MAIL_RESET });
            }, 6000);
        } catch (error) {
            dispatch({
                type: SEND_MAIL_FAIL,
                payload: "Send fail - Server error",
            });
            setTimeout(() => {
                dispatch({ type: SEND_MAIL_RESET });
            }, 6000);
        }
    };

    const sendCV = async () => {
        dispatch({ type: SEND_CV_REQUEST });

        try {
            const file = await axios.get("/api/contact", {
                responseType: "blob",
            });
            FileDownload(file.data, "Peter Drysdale.pdf");
            dispatch({ type: SEND_CV_SUCCESS });
        } catch (error) {
            dispatch({ type: SEND_CV_FAIL, payload: error });
        }
    };

    return (
        <AppContext.Provider
            value={{
                sendMail,
                sendCV,
                mailSuccessMessage: state.mailSuccessMessage,
                mailFailMessage: state.mailFailMessage,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export default AppState;
