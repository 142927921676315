import React from "react";
import Container from "../components/Container";
import Title from "../components/Title";
import ConnectCards from "../components/ConnectCards";

const Contact = () => {
    return (
        <Container backgroundColor="#dba159" height="auto" id="contact">
            <Title rotate="270deg" white>
                Contact
            </Title>
            <ConnectCards />
        </Container>
    );
};

export default Contact;
