import {
  SEND_MAIL_REQUEST,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_FAIL,
  SEND_MAIL_RESET,
  SEND_CV_REQUEST,
  SEND_CV_SUCCESS,
  SEND_CV_FAIL,
  SEND_CV_RESET,
} from "./constants";

const reducer = (state, action) => {
  switch (action.type) {
    case SEND_MAIL_REQUEST:
      return { ...state, mailGetting: true };
    case SEND_MAIL_SUCCESS:
      return {
        ...state,
        mailSuccessMessage: action.payload.message,
        mailGetting: false,
      };
    case SEND_MAIL_FAIL:
      return { ...state, mailFailMessage: action.payload, mailGetting: false };
    case SEND_MAIL_RESET:
      return {
        ...state,
        mailFailMessage: null,
        mailSuccessMessage: null,
        mailGetting: false,
      };
    case SEND_CV_REQUEST:
      return { ...state, cvGetting: true };
    case SEND_CV_SUCCESS:
      return {
        ...state,
        cvSuccess: true,
        cvGetting: false,
      };
    case SEND_CV_FAIL:
      return {
        ...state,
        cvFailMessage: action.payload,
        cvGetting: false,
        cvSuccess: false,
      };
    case SEND_CV_RESET:
      return {
        ...state,
        cvFailMessage: null,
        cvSuccessMessage: null,
        cvGetting: false,
      };

    default:
      return state;
  }
};

export default reducer;
