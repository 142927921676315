import { Link } from "react-router-dom";
import styled from "styled-components";

const LinkStyles = styled.div`
    color: ${(props) => (props.color ? props.color : "black")};
    text-decoration: none;
    :hover {
        color: ${(props) => (props.color ? props.color : "black")};
        text-decoration: none;
    }
    :focus {
        color: ${(props) => (props.color ? props.color : "black")};
        text-decoration: none;
    }
    :active {
        color: ${(props) => (props.color ? props.color : "black")};
        text-decoration: none;
    }
`;

const ExtStyles = styled.a`
    color: ${(props) => (props.color ? props.color : "black")};
    text-decoration: none;
    :hover {
        color: ${(props) => (props.color ? props.color : "black")};
        text-decoration: none;
    }
    :focus {
        color: ${(props) => (props.color ? props.color : "black")};
        text-decoration: none;
    }
    :active {
        color: ${(props) => (props.color ? props.color : "black")};
        text-decoration: none;
    }
`;

const L = (props) => {
    return (
        <>
            {props.external ? (
                <ExtStyles href={`${props.to}`} target="_blank">
                    {props.children}
                </ExtStyles>
            ) : (
                <Link to={props.url}>
                    <LinkStyles>{props.children}</LinkStyles>
                </Link>
            )}
        </>
    );
};

export default L;
