import { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import Link from "./Link";
import Text from "./Text";

const zoomIn = keyframes`
from {transform: scale(1)}
to {transform: scale(1.2)}
`;

const zoomOut = keyframes`
from {transform: scale(1.2)}
to {transform: scale(1)}
`;

const animOut = css`
    ${zoomOut} 0.5s forwards
`;

const animIn = css`
    ${zoomIn} 0.5s forwards
`;

const CardStyles = styled.div`
    width: 400px;

    overflow: hidden;
    border-radius: 10px;
    background-color: white;
    margin: 15px;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
        0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
        0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
        0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
        0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
`;

const ImageStyles = styled.div`
    display: grid;
    overflow: hidden;
`;

const Img = styled.img`
    grid-row: 1;
    grid-column: 1;
    object-fit: cover;
    width: 100%;
    height: 270px;
    animation: ${(props) => (props.focus ? animIn : animOut)};
`;

const SneakyText = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
    color: white;
    opacity: 0;
    grid-row: 1;
    grid-column: 1;
    z-index: 1;
    :hover {
        opacity: 1;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
        );
    }
`;

const PublicTextContainer = styled.div`
    margin: 20px;
`;

const Title = styled.div`
    font-size: 2em;
    font-family: ${(props) => props.theme.fonts.heading};
`;

const Image = ({ src, text }) => {
    const [inFocus, setInFocus] = useState(false);
    return (
        <ImageStyles
            onMouseEnter={() => {
                setInFocus(true);
            }}
            onMouseLeave={() => {
                setInFocus(false);
            }}
        >
            <Img src={src} focus={inFocus} />
            <SneakyText>{text}</SneakyText>
        </ImageStyles>
    );
};

const Card = ({ img, name, publicDetail, hiddenDetail, link }) => {
    return (
        <CardStyles>
            <Link to={link} external>
                <Image src={img} text={hiddenDetail}></Image>
                <PublicTextContainer>
                    <Title>{name}</Title>
                    <Text color="black" hoverColor="black">
                        {publicDetail}
                    </Text>
                </PublicTextContainer>
            </Link>
        </CardStyles>
    );
};

export default Card;
