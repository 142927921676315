import styled from "styled-components";

export default styled.label`
    font-family: ${(props) => props.theme.fonts.heading};
    font-size: ${(props) => (props.fontSize ? props.fontSize : "2em")};
    padding: 10px 0;
    gap: 10px;
    height: auto;
    color: ${(props) => (props.white ? "white" : "inherit")};
    display: flex;
    align-items: center;
    /* text-shadow: ${(props) =>
        props.white && "0.5px 0.5px" + props.theme.colors.two}; */
    :hover {
        cursor: ${(props) => props.linkStyle && "pointer"};
    }
`;
