import React from "react";
import Link from "./Link";
import Text from "./Text";
import Label from "./Label";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 30px;
`;

const CodingScreen = () => {
  return (
    <Wrapper>
      <Label>Github</Label>
      <Link to="https://github.com/pwdrysdale" external>
        <Text color="three" hoverColor="white">
          <i class="fab fa-github"></i> My Profile
        </Text>
      </Link>
      <Label>Codepen</Label>
      <Link to="https://codepen.io/pwdrysdale" external>
        <Text color="three" hoverColor="white">
          <i class="fab fa-codepen"></i> My Profile
        </Text>
      </Link>
      <Label>FreeCodeCamp</Label>
      <Link
        to="https://www.freecodecamp.org/fcce5366a3e-4718-4065-978d-6595af927f39"
        external
      >
        <Text color="three" hoverColor="white">
          <i class="fab fa-free-code-camp"></i> My Profile
        </Text>
      </Link>
    </Wrapper>
  );
};

export default CodingScreen;
