import React, { useState } from "react";
import styled from "styled-components";

const MenuStyle = styled.div`
    position: fixed;
    top: 10px;
    right: 10px;
    height: 100%;
    z-index: 100;
`;

const Permanent = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 30px;
    border: 1px solid white;
    border-radius: 3px;
    color: white;
    background: #34435e;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        background: rgba(255, 255, 255, 0.5);
    }
`;

const ItemsContainer = styled.div`
    position: relative;
    top: 0;
    left: 0;

    width: auto;
    background: ${(props) => props.show === true && "red"};
    display: ${(props) => props.show === false && "none"};
`;

const ItemStyle = styled.div`
    font-family: ${(props) => props.theme.fonts.heading};
    font-size: 2em;
    padding: 20px;
    :hover {
        color: white;
    }
    @media (min-width: 1024px) {
        font-size: 6em;
    }
`;

const Item = ({ src, text }) => {
    return (
        <a href={src} style={{ textDecoration: "none", color: "inherit" }}>
            <ItemStyle>{text}</ItemStyle>
        </a>
    );
};

const Menu = () => {
    const [show, setShow] = useState(false);
    return (
        <MenuStyle>
            <Permanent
                onMouseOver={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            >
                <i className="fas fa-bars"></i>
            </Permanent>
            <ItemsContainer
                onMouseOver={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                show={show}
            >
                <Item src="#welcome" text="Welcome" />
                <Item src="#about" text="About" />
                <Item src="#projects" text="Projects" />
                <Item src="#education" text="education" />
                <Item src="#work" text="work" />
                <Item src="#contact" text="contact" />
            </ItemsContainer>
        </MenuStyle>
    );
};

export default Menu;
