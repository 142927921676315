import styled from "styled-components";

export default styled.div`
  min-height: 100vh;
  width: 100vw;
  //min-height: ${(props) => (props.height ? props.height : "100vh")};
  display: flex;
  padding: ${(props) => props.padding && props.padding};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  background-color: ${(props) => props.backgroundColor};
  background-image: ${(props) =>
    props.gradientColor &&
    `linear-gradient(215deg, ${props.backgroundColor}, ${props.gradientColor})`};
  color: ${(props) => props.color};
  overflow: ${(props) => (props.hidden ? "hidden" : "scroll")};
  position: relative;
`;
