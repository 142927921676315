import { BrowserRouter as Router } from "react-router-dom";
import AppState from "./context/AppState";
import { ThemeProvider } from "styled-components";
import { ParallaxProvider } from "react-scroll-parallax";
import theme from "./theme";
import Welcome2 from "./screens/Welcome2";
import AboutMe from "./screens/AboutMe";
import Projects from "./screens/Projects";
import Education from "./screens/Education";
import WorkHistory from "./screens/WorkHistory";
import Contact from "./screens/Contact";
import Menu from "./components/Menu";
import Welcome from "./screens/Welcome";

// require("typeface-bebas-neue");
// require("typeface-mrs-saint-delafield");
// require("typeface-lora");

function App() {
    return (
        <AppState>
            <ParallaxProvider>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Menu />
                        <Welcome2 />
                        <Welcome />
                        <AboutMe />
                        <Projects />
                        <Education />
                        <WorkHistory />
                        <Contact />
                    </Router>
                </ThemeProvider>
            </ParallaxProvider>
        </AppState>
    );
}

export default App;
