import React from "react";
import Text from "./Text";
import styled, { keyframes } from "styled-components";

const showAnim = keyframes`
    0% {top:-60px}
    10% {top: 10px}
    90% {top: 10px}
    0% {top:-60px}
`;

const ToastStyle = styled.div`
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  top: -60px;
  border-radius: 10px;
  visibility: 0;
  left: calc(50%-150px);
  background: blue;
  animation: ${showAnim} 6s forwards;
`;

const Toast = ({ status = "success", text }) => {
  return (
    <ToastStyle>
      <Text>
        <i className="fas fa-check"></i> {text}
      </Text>
    </ToastStyle>
  );
};

export default Toast;
