import React, { useRef } from "react";
import styled from "styled-components";
import Card from "../components/Card";
import Title from "../components/Title";
import Container from "../components/Container";

const InsideContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    margin-bottom: 60px;
    @media (min-width: 1124px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const Projects = () => {
    const ref = useRef();

    return (
        <Container
            backgroundColor="#3f8efc"
            gradientColor="#2667ff"
            id="projects"
        >
            <InsideContainer>
                <Title alignSelf="center" white>
                    Projects
                </Title>
                <CardsContainer ref={ref}>
                    <Card
                        name="The Prayer Wall"
                        img="/images/theprayerwall.png"
                        publicDetail="A full stack app for prayers"
                        hiddenDetail="Technologies: NodeJS, React, Redux, Styled-Components, JWT, MongoDB"
                        link="https://pwd-the-prayer-wall.herokuapp.com/"
                    />
                    <Card
                        name="Study Chat"
                        img="/images/studychat.jpg"
                        publicDetail="A place to listen to music and chat if you'd like"
                        hiddenDetail="Techologies: NodeJS, Express, React, React Providers, Socket.Io"
                        link="https://pwd-study-chat.herokuapp.com/"
                    />
                    <Card
                        name="SydHelp"
                        img="/images/sydhelp.png"
                        publicDetail="A place for people to give and recieve help"
                        hiddenDetail="Techologies: Same as The Prayer Wall plus Mapbox, Multer, DraftJS"
                        link="https://pwd-sydhelp.herokuapp.com/"
                    />
                    <Card
                        name="Report Writer"
                        img="/images/rw.png"
                        publicDetail="A tool for writing reports / repetitive text"
                        hiddenDetail="Techologies: GraphQL (Apollo Server Express), Postgres (raw SQL), React, Tailwind CSS"
                        link="https://pwd-report-writer.herokuapp.com/"
                    />
                </CardsContainer>
            </InsideContainer>
        </Container>
    );
};

export default Projects;
