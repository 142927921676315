import styled from "styled-components";

export default styled.div`
  font-size: 8em;
  font-family: ${(props) => props.theme.fonts.heading};
  width: 200px;
  overflow-wrap: break-word;
  line-height: 100px;
  transform: ${(props) => props.rotate && `rotate(${props.rotate})`};
  align-self: ${(props) => props.alignSelf && props.alignSelf};
  color: ${(props) => (props.white ? "white" : "black")};
`;
