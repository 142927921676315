import React, { useEffect } from "react";
import Matter from "matter-js";
import styled from "styled-components";

import Container from "../components/Container";

const Name = styled.div`
    font-family: ${(props) => props.theme.fonts.heading};
    text-transform: uppercase;
    color: white;
    font-size: 230px;
    z-index: 1;
`;

const Button = styled.button`
    height: 30px;
    border: 1px solid white;
    border-radius: 30px;
    padding: 10px 30px 25px 30px;
    color: white;
    font-family: ${(props) => props.theme.fonts.text};
    background: none;
    outline: none;
    margin-top: 20px;
    cursor: pointer;
    :hover {
        background: rgba(255, 255, 255, 0.5);
    }
`;

const Welcome2 = () => {
    const {
        Engine,
        Render,
        Runner,
        World,
        Bodies,
        Svg,
        Common,
        Mouse,
        MouseConstraint,
    } = Matter;

    const height = 600;
    const width = 1000;

    const engine = Engine.create();

    function createBall() {
        const ball = Bodies.circle(Math.round(Math.random() * width), -90, 45, {
            angle: Math.PI * (Math.random() * 2 - 1),
            friction: 0.001,
            frictionAir: 0.01,
            restitution: 0.8,
            render: {
                sprite: {
                    texture: "/images/me6.png",
                    /* xScale: 2,
          yScale: 2, */
                },
            },
        });

        return ball;
    }

    const handleClick = () => {
        const ball2 = createBall();
        World.add(engine.world, [ball2]);
    };

    useEffect(() => {
        const main = document.getElementById("main");

        const runner = Runner.create();
        const render = Render.create({
            canvas: main,
            engine: engine,
            options: {
                width: width,
                height: height,
                background: "transparent",
                wireframes: false,
            },
        });

        const boundaryOptions = {
            isStatic: true,
            render: {
                fillStyle: "transparent",
                strokeStyle: "transparent",
            },
        };

        const ground = Bodies.rectangle(
            0,
            height + 50,
            2 * width,
            100,
            boundaryOptions
        );
        const leftWall = Bodies.rectangle(0, 0, 4, 2 * width, boundaryOptions);
        const rightWall = Bodies.rectangle(
            width,
            0,
            4,
            2 * height,
            boundaryOptions
        );

        var vertexSets = [],
            color = Common.choose(["#ffffff"]);

        const name = document
            .getElementById("name")
            .getElementsByTagName("path");
        for (let i = 0; i < name.length; i++) {
            var v = Bodies.fromVertices(
                58 + i * 90,
                0,
                Svg.pathToVertices(name[i], 20),
                {
                    render: {
                        fillStyle: color,
                        strokeStyle: color,
                    },
                },
                true
            );
            vertexSets.push(v);
        }

        vertexSets.push(ground);

        World.add(engine.world, vertexSets);

        Render.run(render);
        Runner.run(runner, engine);

        World.add(engine.world, [ground, leftWall, rightWall]);

        var mouse = Mouse.create(render.canvas);
        var mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: {
                    visible: false,
                },
            },
        });
        mouseConstraint.mouse.element.removeEventListener(
            "mousewheel",
            mouseConstraint.mouse.mousewheel
        );
        mouseConstraint.mouse.element.removeEventListener(
            "DOMMouseScroll",
            mouseConstraint.mouse.mousewheel
        );

        World.add(engine.world, mouseConstraint);
    }, [
        Bodies,
        Common,
        Mouse,
        MouseConstraint,
        Render,
        Runner,
        Svg,
        World,
        engine,
    ]);

    return (
        <Container
            backgroundColor="#34435e"
            gradientColor="#2667ff"
            hidden
            id="welcome"
        >
            <Button id="boop" onClick={handleClick}>
                See My Face
            </Button>
            <Name>Hey, I'm Pete</Name>
            <canvas
                id="main"
                style={{ position: "absolute", bottom: 0 }}
            ></canvas>
            <svg
                id="name"
                width="939"
                height="196"
                viewBox="0 0 939 196"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ visibility: "hidden", scrollBehavior: "ignore" }}
            >
                <path
                    d="M0 0H26.3159V68.122H54.5457V0H80.8617V167.317H54.5457V92.0244H26.3159V167.317H0V0Z"
                    fill="white"
                />
                <path
                    d="M100.46 0H172.231V23.9024H126.776V68.122H162.901V92.0244H126.776V143.415H172.231V167.317H100.46V0Z"
                    fill="white"
                />
                <path
                    d="M211.533 96.0878L179.715 0H207.705L225.648 61.4293H226.126L244.069 0H269.667L237.849 96.0878V167.317H211.533V96.0878Z"
                    fill="white"
                />
                <path
                    d="M267.94 167.317H259.328V141.98H284.687V164.688L271.529 196H259.328L267.94 167.317Z"
                    fill="white"
                />
                <path d="M342.733 0H369.049V167.317H342.733V0Z" fill="white" />
                <path
                    d="M397.37 25.3366H388.758V0H414.117V22.7073L399.524 54.0195H387.323L397.37 25.3366Z"
                    fill="white"
                />
                <path
                    d="M433.848 0H471.408L488.155 119.751H488.633L505.38 0H542.94V167.317H518.059V40.6341H517.581L498.442 167.317H476.432L457.293 40.6341H456.815V167.317H433.848V0Z"
                    fill="white"
                />
                <path
                    d="M600.893 0H639.649C652.727 0 662.536 3.50569 669.075 10.5171C675.614 17.5285 678.884 27.8065 678.884 41.3512V57.8439C678.884 71.3886 675.614 81.6667 669.075 88.678C662.536 95.6894 652.727 99.1951 639.649 99.1951H627.209V167.317H600.893V0ZM639.649 75.2927C643.955 75.2927 647.145 74.0976 649.218 71.7073C651.451 69.3171 652.568 65.2537 652.568 59.5171V39.678C652.568 33.9415 651.451 29.878 649.218 27.4878C647.145 25.0976 643.955 23.9024 639.649 23.9024H627.209V75.2927H639.649Z"
                    fill="white"
                />
                <path
                    d="M693.176 0H764.947V23.9024H719.492V68.122H755.617V92.0244H719.492V143.415H764.947V167.317H693.176V0Z"
                    fill="white"
                />
                <path
                    d="M800.66 23.9024H773.148V0H854.488V23.9024H826.976V167.317H800.66V23.9024Z"
                    fill="white"
                />
                <path
                    d="M867.229 0H939V23.9024H893.545V68.122H929.67V92.0244H893.545V143.415H939V167.317H867.229V0Z"
                    fill="white"
                />
            </svg>
        </Container>
    );
};

export default Welcome2;
