import React from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import Container from "../components/Container";
import Label from "../components/Label";
import Title from "../components/Title";

const Welcome = () => {
    const Bg = styled.div`
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 0;
    `;

    const TextContainer = styled.div`
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: grid;
        margin: auto;
        overflow: none;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr;
        :nth-child(2),
        :nth-child(3),
        :nth-child(4),
        :nth-child(5),
        :nth-child(6) {
            visibility: hidden;
            display: none;
        }

        @media (min-width: 400px) {
            grid-template-columns: repeat(2, 1fr);
            :nth-child(3),
            :nth-child(4),
            :nth-child(5),
            :nth-child(6) {
                visibility: hidden;
                display: none;
            }
        }

        @media (min-width: 768px) {
            :nth-child(5),
            :nth-child(6) {
                visibility: hidden;
                display: none;
            }
            grid-template-columns: repeat(4, 1fr);
        }

        @media (min-width: 1280px) {
            grid-template-columns: repeat(6, 1fr);
        }
    `;

    const TowerWrapper = styled.div`
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 50% 0;
    `;

    const TowerWrapper1 = styled(TowerWrapper)`
        margin: 50% 0;
        display: none;
        @media (min-width: 400px) {
            display: flex;
        }
    `;
    const TowerWrapper2 = styled(TowerWrapper)`
        display: none;
        margin: 50% 0;
        @media (min-width: 768px) {
            display: flex;
        }
    `;
    const TowerWrapper4 = styled(TowerWrapper)`
        margin: 50% 0;
        display: none;
        @media (min-width: 1280px) {
            display: flex;
        }
    `;

    const T = styled(Title)`
        font-size: 9em;
        z-index: 10;
        margin: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        color: white;
    `;
    const TS = styled(Title)`
        text-shadow: 0 0 0.5em #000;
        font-size: 9em;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        color: black;
    `;

    return (
        <Container backgroundColor="#dba159" hidden>
            <TextContainer>
                <TowerWrapper>
                    <TS>Full Stack Developer</TS>
                    <T>Full Stack Developer</T>
                </TowerWrapper>
                <TowerWrapper1>
                    <TS>Full Stack Developer</TS>
                    <T>Full Stack Developer</T>
                </TowerWrapper1>
                <TowerWrapper2>
                    <TS>Full Stack Developer</TS>
                    <T>Full Stack Developer</T>
                </TowerWrapper2>
                <TowerWrapper2>
                    <TS>Full Stack Developer</TS>
                    <T>Full Stack Developer</T>
                </TowerWrapper2>
                <TowerWrapper4>
                    <TS>Full Stack Developer</TS>
                    <T>Full Stack Developer</T>
                </TowerWrapper4>
                <TowerWrapper4>
                    <TS>Full Stack Developer</TS>
                    <T>Full Stack Developer</T>
                </TowerWrapper4>
            </TextContainer>
            <Bg>
                {new Array(20).fill(0).map((_, i) => (
                    <Marquee
                        gradient={false}
                        direction={["left", "right"][Math.round(Math.random())]}
                        speed={Math.random() * 35 + 10}
                    >
                        <Label>
                            {" "}
                            NODEJS : MONGODB : POSTGRES : Graphql : APOLLO :
                            TYPEGRAPHQL : REST : EXPRESS : GIT : NPM :
                            TYPESCRIPT : JAVASCRIPT : CSS : HTML : REACT : REDUX
                            : ZUSTAND : STYLED COMPONENTS : TAILWIND CSS : CSS
                            MODULES : JEST :{"  "}
                        </Label>
                    </Marquee>
                ))}
            </Bg>
        </Container>
    );
};

export default Welcome;
