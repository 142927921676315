import React, { useState } from "react";
import styled from "styled-components";
import Container from "../components/Container";
import Label from "../components/Label";
import Text from "../components/Text";
import Title from "../components/Title";

const SectionWrap = styled.div`
    margin: 10vh 10vw;
    z-index: 1;
`;

const TimelineWrapper = styled.div`
    overflow: scroll;
    border-radius: 10px;
    border-left: 5px solid white;
    padding: 20px 0;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
        0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
        0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
        0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
        0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    @media (min-width: 1124px) {
        width: 50%;
    }
`;

const TimelineItemWrapper = styled.div`
    padding: 0 20px 0 20px;
    overflow: hidden;
    height: auto;
    transition: height 0.5s;
`;

const ShowHideTextStyle = styled.div`
    max-height: ${(props) => (props.show === false ? "100vh" : "0")};
    transition: all;
    transition-duration: 500ms;
    //height: ${(props) => (props.show === false ? "auto" : "0")};
    width: 100%;
    overflow: scroll;
    float: left;
    text-align: left;
`;

const P = styled.div`
    font-family: ${(props) => props.theme.fonts.text};
    margin: 10px 0;
    color: white;
    font-size: 1.2em;
    //text-shadow: 0.5px 0.5px ${(props) => props.theme.colors.two};
`;

const Expander = styled.div`
    font-size: 0.8em;
    transform: ${(props) => (props.show ? "rotate(-90deg)" : "rotate(0deg)")};
    transition: all;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const WhiteThing = () => {
    const size = 100;
    const pointsGenerator = () => {
        return `${size * Math.random()}, ${size * Math.random()}`;
    };

    return (
        <svg height={`${size}px`} width={`${size}px`}>
            <polygon
                points={`${pointsGenerator()} ${pointsGenerator()} ${pointsGenerator()}`}
                style={{ fill: "white", borderRadius: "3px" }}
            />
        </svg>
    );
};

const Randos = styled.div`
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 55vw;
    left: 45vw;
`;

const WhiteThingWrapper = styled.div`
    position: absolute;
    right: 0;
    left: ${(props) => 1000 * Math.pow(props.x, 0.5) + "px"};
    top: ${(props) => 2000 * props.y + "px"};
`;

const Check = styled.input.attrs(() => ({ type: "checkbox" }))`
    display: none;
`;

const arr = () => {
    return new Array(Math.floor(Math.random() * 80) + 100).fill();
};

const TimelineItem = (props) => {
    const [show, setShow] = useState(true);
    return (
        <TimelineItemWrapper show={show} height={props.height}>
            <Check type="checkbox" id={props.title} selected={show} />
            <Label
                onClick={() => {
                    setShow(!show);
                }}
                white
                linkStyle
                htmlFor={props.title}
            >
                <Expander show={show}>
                    {/* {show ? ( */}
                    <i className="fas fa-chevron-down"></i>
                    {/* ) : (
                        <i className="fas fa-chevron-down"></i>
                    )} */}
                </Expander>
                <div>{props.title}</div>
            </Label>
            <ShowHideTextStyle show={show}>
                <Text color="black" hoverColor="black">
                    {props.children}
                </Text>
            </ShowHideTextStyle>
        </TimelineItemWrapper>
    );
};

const WorkHistory = () => {
    return (
        <Container backgroundColor="#3f8efc" height="auto" id="work">
            <Randos>
                {arr().map((x) => (
                    <WhiteThingWrapper x={Math.random()} y={Math.random()}>
                        <WhiteThing />
                    </WhiteThingWrapper>
                ))}
            </Randos>
            <SectionWrap>
                <Title rotate="45deg" white>
                    Work History
                </Title>
                <TimelineWrapper>
                    <TimelineItem
                        title="2014 - Present: The King's School"
                        height="550px"
                    >
                        <P>
                            I began at Australia's oldest independent school -
                            an all boys boarding school - as a Mathematics
                            Teacher in 2014. The responsibilities for a teacher
                            at King’s go beyond the classroom-extending to
                            pastoral and sporting commitments-as teachers are
                            expected to be involved in the life of the school
                            and in raising the ‘entire boy’.
                        </P>
                        <P>
                            In 2017 my wife and I picked up 80 new teenage boys
                            as housemates as we moved into boarding, spending
                            around a year and a half living at work.
                        </P>
                        <P>
                            During this time I also began the role of Reserve
                            Academic Database Coordinator, assisting with
                            managing the schools database (MySQL), becoming
                            familiar with both the user end (procedures, etc)
                            and writing SQL.
                        </P>
                        <P>
                            In 2018 and 2019 I stepped into the Data Analytics
                            Coordinator role where I furthered skills from my
                            previous role, and my responsibilities increased.
                            These included writing SQL, pulling data to create
                            reports in Tableau and advanced use of Google Sheets
                            to analyse and organise data.
                        </P>
                        <P>
                            During 2020 I was promoted to Operations and Data
                            Analyst. This included timetabling (scheduling) the
                            entire secondary school (1450 students and 180
                            staff) for their day to day classes, examinations
                            and other events. Change was a constant, and
                            organisation paramount. With COVID-19 this meant
                            regularly rescheduling and re-rooming classes
                            according to government regulations and the many
                            internal restraints as well as keeping parents
                            paying $40,000 in tuition a year happy. I relied
                            heavily on Google Sheets and timetabling software to
                            achieve a successful outcome.
                        </P>
                    </TimelineItem>

                    <TimelineItem
                        title="2011 - 2013: Northern Beaches Christian School"
                        height="370px"
                    >
                        <P>
                            {" "}
                            <em>
                                “I teach high school math. I sell a product to a
                                market that doesn’t want it, but is forced by
                                law to buy it.” - Dan Meyer
                            </em>
                        </P>
                        <P>
                            I would describe myself as scholarly and technical,
                            so stepping into a people oriented job such as
                            teaching was a (welcomed) challenge. As a young
                            teacher straight from college I learned how to
                            motivate high school students to love (or sometimes
                            tolerate) mathematics.
                        </P>
                        <P>
                            At Northern Beaches Christian School I was one of
                            six Mathematics teachers and taught a load of 100
                            students per academic year. The school was focused
                            on innovation and education which meant I was driven
                            to challenge traditional methods through creativity
                            and flexibility in my teaching approach. In addition
                            I contributed in writing educational programs and
                            student examinations.
                        </P>
                    </TimelineItem>
                    <TimelineItem
                        title="2000 - 2010: Programmed Timber Supplies"
                        height="220px"
                    >
                        <P>
                            Working in the family timber business to pay my way
                            through high school and college, I stacked a lot of
                            wood... and I mean a lot!
                        </P>
                        <P>
                            This job taught me a lot about people, ranging from
                            those on day release from a local jail to near
                            retired professionals-all much older than myself. I
                            also learned the importance of applying yourself and
                            the satisfaction that hard work brings.
                        </P>
                    </TimelineItem>
                </TimelineWrapper>
            </SectionWrap>
        </Container>
    );
};

export default WorkHistory;
