import React, { useEffect, useState, useContext } from "react";
import AppContext from "../context/appContext";
import Link from "./Link";
import Text from "./Text";
import Label from "./Label";
import styled from "styled-components";

const Wrapper = styled.div`
    margin: 30px;
`;

const TraditionalScreen = () => {
    const [time, setTime] = useState("");

    const appContext = useContext(AppContext);
    const { sendCV } = appContext;

    let options = {
            timeZone: "America/New_York",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        },
        formatter = new Intl.DateTimeFormat([], options);

    useEffect(() => {
        setTimeout(() => {
            setTime(formatter.format(new Date()));
        }, 1000);
    }, [time, formatter]);

    return (
        <Wrapper>
            <Label>Phone</Label>
            <Link to="tel:+17273776011" external color="white">
                <Text color="three" hoverColor="white">
                    <i className="fas fa-phone"></i> +1 727 377 6011
                </Text>
            </Link>
            <Text hoverColor="white">
                Current time in Florida (where I am): {`${time}`}
            </Text>
            <Label>Email</Label>
            <Link to="mailto:pwdrysdale@gmail.com" external color="white">
                <Text color="three" hoverColor="white">
                    <i className="fas fa-envelope"></i> pwdrysdale@gmail.com
                </Text>
            </Link>
            <Label>Paper resume</Label>
            <Text hoverColor="white" color="three" linkStyle onClick={sendCV}>
                <i className="fas fa-download"></i> Download
            </Text>
        </Wrapper>
    );
};

export default TraditionalScreen;
