const theme = {
  fonts: {
    heading: ' "Bebas Neue", arial, verdana',
    text: "Lora",
  },
  colors: {
    one: "#3f8efc",
    two: "#7d4e57",
    three: "#3f8efc",
    four: "#212d40",
    five: "#11151c",
    black: "black",
    white: "white",
  },
};

export default theme;
