import React from "react";
import Container from "../components/Container";
import Text from "../components/Text";
import Label from "../components/Label";
import Title from "../components/Title";
import { Parallax } from "react-scroll-parallax";
import styled from "styled-components";

const Wrap = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: 1124px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const ParallaxBackground = styled.div`
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
`;

const TextContainer = styled.div`
    padding: 3vh 4vw 0 4vw;
    display: flex;
    flex-direction: column;

    @media (min-width: 1124px) {
        padding: 3vh 0 0 10vw;
    }
`;

const Education = () => {
    return (
        <Container id="education">
            <Wrap>
                <TextContainer>
                    <Title>Education</Title>
                    <Label color="black" fontSize="30px">
                        Coding - Self Taught
                    </Label>
                    <Text color="black" fontSize="18px" lineHeight="10">
                        Free Code Camp, Stephen Grider, Traversy Media,
                        DevelopedByEd, Coding with Chaim
                    </Text>
                    <Text color="black" fontSize="18px" lineHeight="10">
                        Some projects built as part of courses includes an
                        e-commerce site, a blog app and Electron desktop apps.
                    </Text>
                    <Label color="black" fontSize="30px">
                        The University of New South Wales
                    </Label>
                    <Text color="black" fontSize="18px" lineHeight="10">
                        2007-2010
                    </Text>
                    <Text color="black" fontSize="18px" lineHeight="10">
                        Bachelor of Science (Mathematics) / Bachelor of
                        Education
                    </Text>
                    <Text color="black" fontSize="18px" lineHeight="10">
                        <em>
                            Northern Beaches Christian School Future Teacher
                            Scholarship
                        </em>
                    </Text>
                    <Text color="black" fontSize="18px" lineHeight="10">
                        <em>Academic Achievement Award Scholarship</em>
                    </Text>

                    <Label color="black" fontSize="30px">
                        Northern Beaches Christian School
                    </Label>
                    <Text color="black" fontSize="18px" lineHeight="10">
                        2001 - 2006
                    </Text>
                    <Text color="black" fontSize="18px" lineHeight="10">
                        <em>Graduated Valedictorian</em>
                    </Text>
                </TextContainer>
                <ParallaxBackground>
                    <Parallax y={["500px", "150px"]} x={[0, 0]}>
                        <img
                            src="/images/Frame4.png"
                            alt="Traversy Media"
                            width="100px"
                        />
                    </Parallax>
                    <Parallax y={["200px", "300px"]} x={[10, 20]}>
                        <img
                            src="/images/Frame5.png"
                            alt="Net Ninja"
                            width="100px"
                        />
                    </Parallax>
                    <Parallax
                        slowerScrollRate
                        y={["5px", "600px"]}
                        x={[50, 50]}
                    >
                        <img
                            src="/images/Frame6.png"
                            alt="Developed By Ed"
                            width="200px"
                        />
                    </Parallax>
                    <Parallax x={[5, 10]} y={[700, 500]} slowerScrollRate>
                        <img
                            src="/images/Frame7.png"
                            alt="Coding with Chaim"
                            width="100px"
                        />
                    </Parallax>
                    <Parallax y={["100px", "-500px"]} x={[100, 50]}>
                        <img
                            src="/images/Frame.png"
                            alt="Free Code Camp"
                            width="100px"
                        />
                    </Parallax>
                    <Parallax y={["-300px", "0px"]} x={[30, 30]}>
                        <img src="/images/UNSW.svg" alt="UNSW" width="230px" />
                    </Parallax>
                    <Parallax y={["-200px", "-400px"]} x={[70, 70]}>
                        <img
                            src="/images/NBCS.svg"
                            alt="NBCS"
                            width="200px"
                            style={{ display: "block", height: "auto" }}
                        />
                    </Parallax>
                </ParallaxBackground>
            </Wrap>
        </Container>
    );
};

export default Education;
